import { useRef } from 'react'
import Head from 'next/head'
import Link from 'next/link'
import { DownOutlined } from '@ant-design/icons'
import { Button, useMediaQuery } from '@pig-frontend/uikit'
import ContactUs from '@pig-casino/components/contact-us'
import {
  lobbyHeaderVideo,
  lobbyHeaderDesktopVideo,
  landingBaccaratLinkImage,
  landingRouletteLinkImage,
  landingSicboLinkImage,
  landingCasinoGuaranteeImage,
  landingDemoImage,
  landingCasinoImage,
  landingBaccarat24hoursImage,
} from '@pig-casino/constants/images'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { pageLink } from '@pig-casino/constants/page-name'
import {
  PaymentSection,
  PromotionSection,
  GameCarousel,
  //PartnerSection,
} from '@pig-casino/components/lobby'
import { useRouter } from 'next/router'
import { Section, Image } from '@pig-frontend/uikit'
import { campaignApi, promotionsApi } from '@pig-casino/api'
import {
  withMiddleware,
  revalidate,
} from '@pig-casino/middlewares/get-serverside-props'
import styles from './index.module.scss'
import { useAmp } from 'next/amp'

export const config = { amp: 'hybrid' }

export default function Home() {
  const router = useRouter()
  const isMD = useMediaQuery('md')
  const gameCarouselRef = useRef(null)
  const isAMP = useAmp()

  const goToRegisterPage = () => {
    router.push({ pathname: pageLinkCommon.login })
  }

  const goToLobby = () => {
    router.push({ pathname: pageLinkCommon.lobby })
  }

  const goToGameCarousel = () => {
    const navbarHeight = 64
    window.scrollTo({
      top: gameCarouselRef.current.offsetTop - navbarHeight,
      behavior: 'smooth',
    })
  }

  return (
    <div className={styles.container}>
      <Head>
        <title>คาสิโนออนไลน์ PIGBET เว็บพนันครบวงจร เว็บตรง แจกเครดิตฟรี</title>
        <meta
          name="title"
          content=" คาสิโนออนไลน์ PIGBET เว็บพนันครบวงจร เว็บตรง แจกเครดิตฟรี"
        />
        <meta
          property="og:title"
          content=" คาสิโนออนไลน์ PIGBET เว็บพนันครบวงจร เว็บตรง แจกเครดิตฟรี"
        />
        <meta
          name="twitter:title"
          content=" คาสิโนออนไลน์ PIGBET เว็บพนันครบวงจร เว็บตรง แจกเครดิตฟรี"
        />

        <meta
          name="description"
          content="คาสิโนออนไลน์ PIGBET เว็บพนันครบวงจร เว็บตรง แจกเครดิตฟรี บาคาร่า รูเล็ต ไฮโล ที่ดีที่สุดในประเทศไทย แตกง่าย จ่ายจริง ถอน-โอนสะดวก มีครบทุกค่ายดังยอดนิยมรวมไว้แล้วที่นี่"
        />
        <meta
          property="og:description"
          content="คาสิโนออนไลน์ PIGBET เว็บพนันครบวงจร เว็บตรง แจกเครดิตฟรี บาคาร่า รูเล็ต ไฮโล ที่ดีที่สุดในประเทศไทย แตกง่าย จ่ายจริง ถอน-โอนสะดวก มีครบทุกค่ายดังยอดนิยมรวมไว้แล้วที่นี่"
        />
        <meta
          name="twitter:description"
          content="คาสิโนออนไลน์ PIGBET เว็บพนันครบวงจร เว็บตรง แจกเครดิตฟรี บาคาร่า รูเล็ต ไฮโล ที่ดีที่สุดในประเทศไทย แตกง่าย จ่ายจริง ถอน-โอนสะดวก มีครบทุกค่ายดังยอดนิยมรวมไว้แล้วที่นี่"
        />
      </Head>
      <section className={styles.header}>
        {!isAMP && (
          <>
            {isMD ? (
              <video autoPlay loop muted className={styles.video}>
                <source src={lobbyHeaderDesktopVideo} type="video/mp4" />
              </video>
            ) : (
              <video autoPlay loop muted className={styles.video}>
                <source src={lobbyHeaderVideo} type="video/mp4" />
              </video>
            )}
          </>
        )}
        {}
        <div className={styles.content}>
          <h1 className={styles.title}>
            PIGBET เว็บคาสิโนออนไลน์ เว็บตรง ครบวงจร ล่าสุด
          </h1>
          {/* <div>
            <span className={styles.tag}>TOP1</span>
            <span>อันดับ 1 ในไทยตอนนี้</span>
          </div> */}
          <Button
            type="primary"
            onClick={goToRegisterPage}
            className={styles.btn}
          >
            สมัคร
          </Button>
          <Button type="default" onClick={goToLobby} className={styles.btn}>
            หน้าแรก
          </Button>
          <DownOutlined onClick={goToGameCarousel} />
        </div>
      </section>

      <div ref={gameCarouselRef}>
        <GameCarousel />
      </div>
      <PromotionSection />
      <PaymentSection />
      {/*
      <PartnerSection
        className={styles.partner}
        imageWrapperClassName={styles['image-wrapper']}
      />
      */}

      <Section title={{ h2: 'คาสิโนออนไลน์ เว็บตรง เปิดใหม่ มีครบทุกเกมดัง' }}>
        <span>
          คาสิโนออนไลน์ เว็บตรง เปิดใหม่ 2023 ที่ครบวงจรที่สุด
          ด้วยระบบเว็บไซต์ใหม่ล่าสุด
          พร้อมยกขบวนเกมพนันออนไลน์มาให้คุณได้เลือกเล่นแบบหลากหลาย เช่น
          เกมส์ไพ่ยอดฮิตอย่าง บาคาร่าออนไลน์ (Baccarat) , รูเล็ต ออนไลน์
          (Roulette) , ไฮโล, แบล็คแจ็ค Blackjack, เสือมังกร และ เกมโชว์
          ก็มีให้นักเดิมพันได้มากอบโกยเงินรางวัลกันไปเลย โดยเว็บคาสิโนออนไลน์
          (Online Casino) PIGBET นี้ จะให้บริการคาสิโนแบบ Live Casino หรือ
          คาสิโนไลฟ์สด คือผู้เล่นสามารถลงเงินเดิมพันแบบ Real Time
          คุณยังสามารถพูดคุย โต้ตอบกับดีลเลอร์ รวมไปถึงผู้เล่นคนอื่นๆ
          ได้แบบเรียลไทม์ เสมือนนั่งอยู่ในคาสิโนจริงๆ เรียกได้ว่า
          ใครที่ชอบการเล่นคาสิโนสด ต้องเพลิดเพลินอย่างแน่นอน
        </span>
        <br />
        <ul className={styles['seo-link']}>
          <li>
            <Link href={pageLink.seo.bacara}>
              <Image
                src={landingBaccaratLinkImage}
                alt="บาคาร่า"
                width={500}
                className={styles.image}
              />
            </Link>
          </li>
          <li>
            <Link href={pageLink.seo.roulette}>
              <Image
                src={landingRouletteLinkImage}
                alt="รูเล็ต"
                width={500}
                className={styles.image}
              />
            </Link>
          </li>
          <li>
            <Link href={pageLink.seo.highlow}>
              <Image
                src={landingSicboLinkImage}
                alt="ไฮโล"
                width={500}
                className={styles.image}
              />
            </Link>
          </li>
        </ul>
      </Section>

      <Section
        title={{ h2: 'ทำไม PIGBET จึงเป็นเว็บคาสิโนออนไลน์ที่ใครๆ ก็พูดถึง' }}
        sectionClassName={styles['section-why-pigbet']}
      >
        <div className={styles.center}>
          <Image
            src={landingCasinoGuaranteeImage}
            alt="คาสิโน การันตี 24ชั่วโมง"
            width={500}
            className={styles.image}
          />
        </div>
        <h3 className={styles.title}>เว็บคาสิโนออนไลน์รูปแบบใหม่ </h3>
        <span>
          เว็บตรง ไม่ต้องโยกเว็บ หรือ กระเป๋าตังค์
          ก็สามารถเล่นเกมได้ครบทุกความต้องการ{' '}
        </span>
        <h3 className={styles.title}>โปรโมชั่นเยอะจุใจ</h3>
        <span>
          สำหรับสายเก็บโปรฯ ต้องปลื้ม เพราะเว็บคาสิโน เว็บตรง แห่งนี้
          มีโปรโมชั่น ให้ได้รับความคุ้มค่ามากมาย ไม่ว่าจะเป็น เครดิตฟรี,
          โปรโมชั่น แนะนำเพื่อน, หรือ คืนยอดเสีย
        </span>
        <h3 className={styles.title}>คาสิโนเว็บตรง เติม - ถอน ไว </h3>
        <span>
          เล่นที่ PIGBET คาสิโนออนไลน์ บาคาร่าออนไลน์ เว็บตรง การันตี
          การเติมเงินแบบ เติม ถอน ไว ใน 3 นาที
          พร้อมรองรับรูปแบบการเติมเงินที่หลากหลาย ครบทุกความสะดวกที่ลูกค้ามองหา
          กับระบบออโต้ที่ช่วยให้เติมเงิน หรือ ถอนเงิน แบบอัตโนมัติ{' '}
        </span>
        <h3 className={styles.title}>
          คาสิโนสด (Live Casino) ภาษาไทย โดย ดีลเลอร์คนไทย
        </h3>
        <span>
          สนุก ตื่นเต้น ได้อรรถรส กับดิลเลอร์คนไทย สามารถแชทพูดคุยตอบโต้
          ผู้เล่นคนอื่นๆ ได้ ไม่ต้องเล่นเงียบๆ อีกต่อไป
        </span>
        <h3 className={styles.title}>เว็บคาสิโนถูกกฎหมาย ได้มาตรฐานสากล</h3>
        <span>
          อยากเล่นเว็บคาสิโนถูกกฎหมาย บาคาร่าออนไลน์ ไม่ต้องเดินทางไปไกล
          อยู่ที่ไหนก็สามารถร่วมสนุกและลุ้นรับเงินรางวัลก้อนโตได้ โดยเว็บ PIGBET
          แห่งนี้ ได้รับ การรองรับการใช้ แอปพลิเคชั่น ประเภท Remote Gambling
          ที่ได้รับใบอนุญาต (Licensing) ให้มีการดำเนินการ
          อย่างถูกต้องตามกฎหมายและควบคุมดูแลภายใต้กฏหมายของประเทศกัมพูชา
          และมีมาตรการกำกับดูแล ข้อมูลส่วนบุคคลตามพระราชบัญญัติข้อมูลส่วนบุคคล
          พ.ศ. 2563 ของประเทศไทย ทั้งนี้ pigbet.app อยู่ภายใต้การดูแลของบริษัท
          LIN PULI INVESMENT CO., LTD.
        </span>
      </Section>

      <Section className={styles.center}>
        <Link href={pageLink.seo.demoBacara}>
          <Image
            src={landingDemoImage}
            width={500}
            alt="ทดลองเล่นบาคาร่าออนไลน์"
            className={styles.image}
          />
        </Link>
      </Section>

      <Section title={{ h3: 'กิจกรรมแจกรางวัลสำหรับสมาชิก คาสิโน เว็บตรง' }}>
        <span>
          คอมมูนิตี้สำหรับคนรักคาสิโนออนไลน์ที่ดีที่สุดแห่งนี้
          มีกิจกรรมที่แจกรางวัลสำหรับเพื่อนสมาชิกคาสิโน เว็บตรง ตลอดทั้งปี
          นอกจากจะได้รับเงินรางวัลที่แตกบ่อยจากเว็บแล้ว
          ยังมีโอกาสได้รับรางวัลเด็ดๆ เช่น เครดิตฟรี และอื่นๆ อีกมากมายด้วย
          เฉพาะสมาชิก PIGBET เท่านั้นที่จะได้รับสิทธิพิเศษนี้ อย่ารอช้า รีบกด
          สมัครเลย!
        </span>
      </Section>

      <Section className={styles.center}>
        <Link href={pageLinkCommon.promotions}>
          <Image
            src={landingCasinoImage}
            width={500}
            alt="ดูโปรโมชั่น พนันออนไลน์ เว็บตรง"
            className={styles.image}
          />
        </Link>
      </Section>

      <Section
        title={{ h2: 'PIGBET ผู้ให้บริการ คาสิโนออนไลน์ไลฟ์สด ที่ดีที่สุด' }}
      >
        <span>
          PIGBET คือ ผู้ให้บริการเว็บ คาสิโนไลฟ์สด ที่ดีที่สุด
          นำทีมโดยผู้เชี่ยวชาญชั้นนำจากต่างประเทศในธุรกิจ คาสิโนออนไลน์
          เว็บพนันออนไลน์ ทำให้ผู้เล่นทุกคนสามารถมั่นใจได้ว่า
          จะได้รับประสบการณ์ที่ดีที่สุดจากเว็บคาสิโนออนไลน์สดแห่งนี้
          ไม่ว่านักเดิมใหม่มือใหม่หรือมืออาชีพ
          ก็ต้องก็สามารถสัมผัสประสบการณ์คาสิโนออนไลน์เสมือนจริงที่ดีที่สุดได้
          ซึ่งที่นี้ มีประสบการณ์ที่จะมอบให้เหล่านักเดิมพันได้รู้สึก
          สนุกและเพลินเพลินกับการพนันที่หลากหลาย
          พร้อมการบริการระดับมืออาชีพและยังครบวงจรความสนุกอีกด้วย
        </span>
      </Section>

      <Section
        title={{ h2: 'ไลฟ์คาสิโนออนไลน์สด เดิมพันได้ 24 ชั่วโมง ไม่มีสะดุด' }}
      >
        <span>
          ให้คุณเล่นคาสิโนออนไลน์สด เดิมพันได้ 24 ไม่มีสะดุด
          ไม่ว่าจะออนไลน์ผ่านคอมพิวเตอร์ หรือ โทรศัพท์มือถือ คาสิโนสด
          ก็พร้อมให้คุณสนุกดิลเดอร์แบบ Real time
          ให้คุณได้บรรยากาศเหมือนกับคุณได้นั่งเล่นอยู่ในคาสิโนจริง
          ไม่ว่าจะแจกไพ่ เปิดไพ่ หรือดูชนะเงินรางวัล
          ก็ดำเนินเกมในแต่ละตาแบบเรียลไทม์
          อย่าลืมเช็คระบบอินเตอร์เน็ตให้ดีเพื่อไม่ให้พลาดทุกการเคลื่อนไหวเหมือนนั่งที่โต๊ะคาสิโน
          <br />
          <br />
          ยิ่งไปกว่านั้น เทคโนโลยีการถ่ายทอดสดที่เหนือชั้น
          บรรยากาศในห้องเกมต่างๆ ถูกปรับให้มีความตื่นเต้น
          รวมถึงกติการในการเล่นเกมต่างๆ ที่มีตั้งแต่เกมคาสิโนมาตรฐาน และ
          เวอร์ชั่นที่ปรับให้รายละเอียดเกมมีความน่าตื่นเต้นขึ้น
          แจกเงินรางวัลจากการเดิมพันที่สูงขึ้น เรียกว่า ลงทุน 100
          ได้กลับเป็นเงินแสน จะไม่ใช่เรื่องไกลตัวอีกต่อไป
          <br />
          <br />
          แน่นอนว่า นอกจากการเล่นคาสิโนออนไลน์ได้ตลอด 24 ชั่วโมงแล้ว
          ก็ยังสามารถติดต่อกับทีมงาน PIGBET ได้ไม่หนีหาย
          เพราะที่นี้มีทีมงานคุณภาพคอยสแตนบายช่วยเหลือตลอด 24 ชั่วโมง
        </span>
      </Section>

      <Section className={styles.center}>
        <Link href={pageLinkCommon.login}>
          <Image
            src={landingBaccarat24hoursImage}
            alt="สมัคร คาสิโนออนไลน์"
            width={500}
            className={styles.image}
          />
        </Link>
      </Section>

      <Section title={{ h2: 'คาสิโนออนไลน์เว็บตรง ฝาก ถอน ไม่มีขั้นต่ำ' }}>
        <span>
          ใครที่ยังลังเลอยู่ว่า อยากจะทดลองเล่นคาสิโนสด เว็บตรง
          แต่ไม่แน่ใจว่าจะเล่นดีมั้ย สนุกมั้ย และ ได้เงินจริงมั้ย? ที่ PIGBET
          แห่งนี้มีทุกคำตอบให้กับคุณ เพราะที่นี้คือ เว็บคาสิโนออนไลน์เว็บตรง
          ที่ให้คุณ ฝาก ถอน ได้ไม่มีขี้นต่ำ ทุนน้อย ก็เล่นได้ ทุนเยอะก็ถอนเร็ว
          คาสิโนออนไลน์ ไลฟ์สด
          ที่จะให้ประสบการณ์ที่ตื่นเต้นไม่ต่างจากที่คาสิโนจริงๆ
          ด้วยการถ่ายถอดสดคุณภาพที่ดีที่สุด ภาพคมชัด
          เสียงประกอบที่ชวนให้ตื่นเต้นและช่วยสร้างความเพลิดเพลินยิ่งขึ้น Live
          กันสดๆ เล่นกันได้ตลอดทุกวัน ให้บริการแบบเต็มพิกัดไม่มีวันหยุด
        </span>
      </Section>

      <Section
        title={{ h2: 'คาสิโนออนไลน์​รูปแบบใหม่ แจกเครดิตฟรี ทุนฟรี สมัครเลย' }}
      >
        <span>
          ต้องบอกเลยว่า คาสิโนออนไลน์รูปแบบใหม่ ที่แจกเครดิตฟรี
          ให้ทุนฟรีกับเพื่อนๆ ได้ไม่มีเงื่อนไข แบบนี้ มีอยู่ที่ PIGBET ที่เดียว
          เพียงแค่สมัครสมาชิก PIGBET วันนี้ เพื่อนๆ ก็สามารถรับ{' '}
          <Link href={pageLinkCommon.promotionsSlug.freeCredit}>
            เครดิตฟรี100
          </Link>{' '}
          ได้เลยทันที ไม่ต้องร่วมกิจกรรม เพียงแค่สมัคร ยืนยันตัวตนสำเร็จ
          ก็ใช้เงิน 100 เอาไว้เป็นทุนเงินเดิมพันได้เลย สมัครกันเสร็จแล้ว
          ก็ไปเดิมพันเก็บกำไรจากคาสิโนออนไลน์ที่นี้กันให้สุดๆ ไปเลย!
        </span>
      </Section>

      <Section title="ติดต่อเรา" subTitle="สะดวกสบาย ติดต่อเราได้ 24 ชั่วโมง">
        <ContactUs className={styles['contact-us']} />
      </Section>
    </div>
  )
}

export const getStaticProps = withMiddleware(async ({ queryClient }) => {
  await queryClient.prefetchQuery([promotionsApi.key.list], () =>
    promotionsApi.list({ productType: 'PIG_BET' }),
  )

  await queryClient.prefetchQuery([campaignApi.key.list], () =>
    campaignApi.list({ types: 'leaderboard', productType: 'PIG_BET' }),
  )

  return { revalidate, props: {} }
})
